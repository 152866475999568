<template>
  <div class="home">
    <div v-if="isLoading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>
    <div v-else>
      <section class="form-section">
        <div class="title-section d-flex">
          <h4 class="title">序號登錄</h4>
          <div class="scanner">
            <button class="svg-btn scanner-btn" @click="scanBarcode">
              <img src="@/assets/images/scannerIcon.svg" alt="">
            </button>
            <span class="scanner-text">掃描序號</span>
          </div>
        </div>
        <!-- 相機預覽 -->
        <!-- 登入序號 -->
        <div class="form-item">
          <div class="form-title-section">
            <div class="form-title required"><span class="required-star">*</span>設備序號</div>
            <a class="form-hint">找不到設備序號</a>
          </div>

          <div class="form-content d-flex">
            <input type="text" class="flex-1 focus-style" placeholder="請輸入設備序號" @blur="fetchDeviceInfo" v-model="deviceSn" ref="deviceSnInput">
          </div>
        </div>

        <!-- 設備掃麻提示 -->
        <div v-if="searchProductName" class="form-item">
          <span class="form-hint">{{ searchProductName }}</span>
        </div>

        <div class="form-item">
          <div class="form-title required"><span class="required-star">*</span>購買日期</div>
          <div class="form-content d-flex">
            <div class="date-picker">
              <VueDatePicker 
                ref="datepicker"
                v-model="purchasingDate" 
                :max="todayDate" 
                :clearable="false" 
                :format="format" 
                :enable-time-picker="false" 
                :max-date="new Date()" 
                hide-input-icon 
              />
              <img src="@/assets/images/calander.svg" alt="" @click="this.openMenu">
            </div>
          </div>
        </div>

        <!-- 購買通路 -->
        <div class="form-item">
          <div class="form-title required"><span class="required-star">*</span>購買通路</div>
          <div class="form-content d-flex flex-column">
            <div class="select-wrap">
              <select class="focus-style" v-model="channel">
                <option value="">請選擇購買通路</option>
                <option v-for="channel in purchaseChannels" :key="channel.value" :value="channel.value">
                  {{ channel.title }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section class="submit-section">
        <div class="submit-btn" :class="{ 'active': allFieldsFilled }" @click="submitForm" :disabled="!allFieldsFilled">
          送出
        </div>
        <div class="cancel-btn" @click="cancelForm">取消</div>
      </section>
      <div class="warring-section" v-if="isWarring">
        <img class="warring-icon" src="@/assets/images/warring.svg" alt="">
        <span class="warring-text">{{ warringText }}</span>
      </div>

    </div>

    <div class="scaner-page" v-show="this.showCamera">
      <div id="barcode-reader">
        <!-- 条码阅读器 -->
      </div>
      <button class="back-btn" @click="handleStop">
          <img src="@/assets/images/backIcon.svg" alt="">
        </button>
    </div>
    
  </div>
</template>

<script>
import { useHtml5QrCode } from '../../hooks/index';
import liff from '@line/liff'


export default {
  name: 'OnlineConsultation',
  data() {
    return {
      showCamera: false,
      html5QrCode: null,
      searchProductName: '',
      purchaseChannels: [], 
      isWarring: false, 
      deviceSn: '', 
      purchasingDate: '',
      channel: 0, 
      allFieldsFilled: false, 
      deviceSnId: '',
      lineUserId: 'line id',
      profile: null,
      warringText: '序號錯誤',
      isLoading: true,
      todayDate: new Date().toISOString().split('T')[0],
    };
  },
  
  mounted() {
    this.getProfile().then(() => {
      this.checkRegistration().then(() => {
        this.html5QrCode = useHtml5QrCode("barcode-reader");
        this.fetchPurchaseChannels(); // 載入頁面時查詢購買通路選項
        this.isLoading = false;
        });
    });
  },
  watch: {
    // 監聽 deviceSn, purchasingDate, channel 的變化
    deviceSn() {
      this.checkAllFieldsFilled();
    },
    purchasingDate(newDate) {
      const today = new Date(newDate).toISOString().split('T')[0];
      this.purchasingDate = today;
      this.checkAllFieldsFilled();
    },
    channel() {
      this.checkAllFieldsFilled();
    }
  },
  methods: {
    async getProfile() {
      try {
        const profile = await liff.getProfile()
        console.log(profile)
        this.profile = profile
        this.lineUserId = profile.userId
        // alert(profile)
        // alert(this.lineUserId)
      } catch (err) {
        console.error('Error getting profile:', err)
      }
    },
    qrCodeSuccessCallback(decodedText) {
      // 傳入 searchProductName 去做後續處理
      this.deviceSn = decodedText;
      // 停止相機
      this.handleStop();
      this.fetchDeviceInfo();
    },
    scanBarcode() {
      if (this.html5QrCode) {
        this.html5QrCode.start(this.qrCodeSuccessCallback);
        this.showCamera = true;
      }
    },
    handleStop() {
      if (this.html5QrCode) {
        this.html5QrCode.handleStop();
        this.showCamera = false;
      }
    },
    fetchPurchaseChannels() {
      this.$axios.post('/api/common', {
        cnames: ["channel"]
      })
      .then(response => {
        if (response.data.code === 0) {
          this.purchaseChannels = response.data.data[0].dp;
        } else {
          console.error('Error fetching purchase channels:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error fetching purchase channels:', error);
      });
    },
    fetchDeviceInfo() {
      // A02 取得設備資訊
      this.$axios.get(`/api/device/${this.deviceSn}`)
        .then(response => {
          if (response.data.code === 0 && response.data.data) {
            console.log('Device info:', response.data);
            this.isWarring = false; 
            this.searchProductName = `${response.data.data.productModel} ${response.data.data.productName}`; 
            this.deviceSnId = response.data.data.deviceSnId;
          } else {
            console.error('Error fetching device info:', response.data.message);
            this.isWarring = true;
            this.searchProductName = '設備序號錯誤';
            this.warringText = '序號錯誤';
            setTimeout(() => {
              this.isWarring = false;
            }, 2000);
          }
        })
        .catch(error => {
          console.error('Error fetching device info:', error);
          this.isWarring = true;
          this.searchProductName = '設備序號錯誤';
          this.warringText = '序號錯誤';
          setTimeout(() => {
            this.isWarring = false;
          }, 2000);
        });
    },
    checkAllFieldsFilled() {
      this.allFieldsFilled = this.deviceSn && this.purchasingDate && this.channel;
    },
    submitForm() {
      // A03 送出設備資訊
      if (this.allFieldsFilled) {
          this.$axios.post('/api/device', {
          lineUserId: this.lineUserId,
          deviceSnId: this.deviceSnId,
          purchasingDate: this.purchasingDate,
          channel: this.channel
        })
        .then(response => {
          if (response.data.code === 0) {
            this.$router.push('/loginSerialNumber/complete'); 
            console.log('Device info:', response.data);
          } else {
            this.isWarring = true;
            this.warringText = response.data.message;
            setTimeout(() => {
              this.isWarring = false;
            }, 2000);
            console.error('Error fetching device info:', response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching device info:', error);
          this.isWarring = true;
          this.warringText = '發送失敗';
          setTimeout(() => {
            this.isWarring = false;
          }, 2000);
        });
      }
    },
    cancelForm() {
      this.deviceSn = '';
      this.purchasingDate = '';
      this.channel = '';
      console.log('表單已重置');
      // 關閉 liff
      liff.closeWindow();
    },
    async checkRegistration() {
      try {
        const response = await this.$axios.get(`/api/check/line?lineUserId=${this.lineUserId}`);
        if (response.data.code !== 0) {
          alert("用戶尚未註冊")
          this.$router.push('/memberRegister')
          console.error('User not registered:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    },
    format(date) {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${year}-${month}-${day}`;
    },
    openMenu() {
      const datepicker = this.$refs.datepicker;
      if (datepicker) {
        datepicker.toggleMenu();
      }
    }
  },
  
};
</script>

<style lang="less">
@import url('~@/less/params.less');

.form-hint {
  font-size: 16px;
  color: @primaryDarkColor;
  font-weight: 500;
}
.warring-section {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9E5E5;
  padding: 8px 15px;
  border-radius: 10px;
  border: 1px solid #F5C1C1;
  width: 100%;
  .warring-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .warring-text {
    font-size: 14px;
    color: #C00000;
  }
}


.back-btn {
  position: absolute;
  bottom: 80px;
  transform: translateX(-50%);
  left: 50%;
  z-index: 999;
  border: none;
  padding: 0;
  width: 42px;
  height: 42px;
  background-color:rgba(0, 0, 0, 0);
  img {
    width: 100%;
    height: 100%;
  }
}

.home {
  position: relative;
}
#barcode-reader {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

.scaner-page {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  margin: -20px;
  overflow: hidden;
  background-color: #4c4c4c;
}

.title-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  .scanner {
    display: flex;
    align-items: center;
    flex-direction: column;

    .scanner-btn {
      width: 28px;
      height: 28px;

    }

    .scanner-text {
      font-size: 12px;
    }
  }
}

.camera-preview {
  margin-top: 20px;

  video {
    width: 100%;
    height: auto;
    border: 1px solid #ddd;
  }
}
</style>

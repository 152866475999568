import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import liff from '@line/liff'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

axios.defaults.baseURL = process.env.VUE_APP_API_URL; 
// axios.defaults.baseURL = 'https://dps-aosmith-api-dev.karweecloud.tw'; 

const app = createApp(App)

app.config.globalProperties.$axios = axios; 

app.use(router)

app.component('VueDatePicker', VueDatePicker);

app.mount('#app')

liff.init({ liffId: process.env.VUE_APP_LIFF_ID })
  .then(async () => {
    if (liff.isLoggedIn()) {
      console.log(liff.getProfile());
    } else {
      liff.login();
    }
  })
  .catch((err) => {
    console.log(err);
  })

<!-- 選單 - 線上諮詢 -->
<template>
  <div class="home">
    <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>
    <section class="form-section-container">
      <div class="form-section-content">
        <h4 class="title">線上諮詢</h4>
        <!-- 服務地區 -->
        <div class="form-item">
          <div class="form-title required"><span class="required-star">*</span>服務地區</div>
          <div class="form-content d-flex flex-column">
            <div class="select-wrap">
              <select class="focus-style" v-model="selectedArea" @change="updateImageUrl">
                <option value="">請選擇服務地區</option>
                <option v-for="area in serviceAreas" :key="area.value" :value="area.value">{{ area.title }}</option>
              </select>
            </div>
          </div>
          <!-- 點擊下方，加入LINE官方好友 -->
          <div class="form-content-btn" v-if="imageUrl">
            <p class="form-content-btn-text">點擊下方，加入LINE官方好友</p>
          </div>
        </div>
      </div>
      <div class="form-section-content-center" v-if="imageUrl">
        <a class="image-container" :href="imageUrl">
          <img src="../../assets/images/line_official.png" alt="線上諮詢">
        </a>
      </div>
      <div class="form-section-content"></div>
    </section>
  </div>
</template>

<script>

import liff from '@line/liff'

export default {
  name: 'OnlineConsultation',
  data() {
    return {
      serviceAreas: [],
      imageUrl: '',
      selectedArea: '',
      isLoading: true,
    };
  },
  mounted() {
    this.fetchServiceAreas();

    this.getProfile().then(() => {
      this.checkRegistration().then(() => {
        this.fetchServiceAreas().finally(() => {
          this.isLoading = false;
        });
        });
    });
  },
  methods: {
    async getProfile() {
      try {
        const profile = await liff.getProfile()
        console.log(profile)
        this.profile = profile
        this.lineUserId = profile.userId
        // alert(profile)
        // alert(this.lineUserId)
      } catch (err) {
        console.error('Error getting profile:', err)
      }
    },
    async checkRegistration() {
      try {
        const response = await this.$axios.get(`/api/check/line?lineUserId=${this.lineUserId}`);
        if (response.data.code !== 0) {
          alert("用戶尚未註冊")
          this.$router.push('/memberRegister')
          console.error('User not registered:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    },
    async fetchServiceAreas() {
      this.$axios.get('/api/line/oa/url')
        .then(response => {
          if (response.data.code === 0) {
            this.serviceAreas = response.data.data.map(area => ({
              value: area.lineOaUrl,
              title: area.serviceRegion
            }));
          }
        })
        .catch(error => {
          console.error('Error fetching service areas:', error);
        });
    },
    updateImageUrl() {
      this.imageUrl = this.serviceAreas.find(area => area.value === this.selectedArea)?.value || '';
    },
    
  }
}
</script>

<style lang="less">
.form-content-btn {
  margin-top: 10px;
}
.form-content-btn-text {
  font-size: 16px;
  color: #666;
  text-align: center;
}
.form-section-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.form-section-content {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  flex:1;
}
.form-section-content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex:1;
  .image-container {
    width: 152px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

</style>

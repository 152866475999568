<!-- 選單 - 主畫面 -->
<template>
    <div class="home">
      <!-- Loading 畫面 -->
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>
  </div>
</template>

<script>
// import liff from '@line/liff';
export default {
  name: 'HomeView',
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    // this.getProfile().then(() => {
    //   this.checkRegistration().then(() => {
    //     this.fetchDevices().finally(() => {
    //       this.isLoading = false;
    //     });
    //   });
    // });
  },
  methods: {
  }
}
</script>


import { Html5Qrcode } from 'html5-qrcode'

export const useHtml5QrCode = (domId) => {
    const html5QrCode = new Html5Qrcode(domId)

    // 啟動相機  開啟掃描功能
    // 傳入 qrCodeSuccessCallback 掃描成功後要做的事
    const start = (qrCodeSuccessCallback = () => {}) => {

        const width = window.innerWidth
        const height = window.innerHeight
        const aspectRatio = width / height
        const reverseAspectRatio = height / width

        const mobileAspectRatio = reverseAspectRatio > 1.5
        ? reverseAspectRatio + (reverseAspectRatio * 12 / 100)
        : reverseAspectRatio


        // 相機brcode顯示設定
        const brConfig = { 
            fps: 10, 
            qrbox: { width: 300, height: 300 },
            videoConstraints: {
                facingMode: 'environment',
                aspectRatio: width < 600
                  ? mobileAspectRatio
                  : aspectRatio,
              },
            disableFlip :true 
        }
        
        // 套件啟動相機function
        html5QrCode.start(
            // 使用預設前或後鏡頭 （environment 為使用預設）
            { facingMode: 'environment' },
            // 相機brcode顯示設定
            brConfig,
            // 掃描成功後的 Callback
            qrCodeSuccessCallback,
            // Error的 Callback
            qrCodeErrorCallback
        )
    }
  
    // 關閉相機
    const handleStop = () => {
        try {
            html5QrCode
                .stop()
                .then(() => {
                    html5QrCode.clear()
                })
                .catch((error) => {
                    console.log('danger', error.message)
                })
        } catch (error) {
            console.log('danger', error)
        }
    }

    // Error的 Callback
    const qrCodeErrorCallback = (error) => {
        console.log('qrCodeErrorCallback error', error)
    }

    return {
        start,
        handleStop
    }
}
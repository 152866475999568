<template>
  <div id="app">
    <div class="container">
      <router-view />

    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
@import url('~@/less/params.less');

.container{
  padding: 20px;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin-right: 10px;
}

nav ul li a {
  text-decoration: none;
  color: #42b983;
}

nav ul li a:hover {
  color: #35495e;
}

* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}
body {
  margin: 0;
  font-family: Noto Sans TC;
}

#app {
  // font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #F8F8F8;
  font-size: 16px;
}
h1,h2,h3,h4,h5,h6 {
  margin: 0;
}
h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 125%;
}
h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 125%;
}
h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 125%;
}
h4 {
  font-style: normal;
  font-weight: "bold";
  font-size: 20px;
  line-height: 125%;
}
h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 125%;
}
.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
  // label {
  //   border: 1px solid @borderColor;
  //   border-radius: 10px;
  //   margin-bottom: 24px;
  //   &:nth-last-child(1) {
  //     margin-bottom: 0;
  //   }
  // }
  // select {
  //   margin-bottom: 24px;
  //   &:nth-last-child(1) {
  //     margin-bottom: 0;
  //   }
  // }
  
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid @primaryColor;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.select-wrap {
  // margin-bottom: 24px;
  position: relative;
  flex: 1;
  &::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%239E9E9E' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    z-index: 1;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
}

.input-wrap {
  position: relative;
  display: flex;
  flex: 1;
  .verification-btn {
    position: absolute;
    right: 12px;
    transform: translateY(-50%);
    top: 50%;
    padding: 6px 10px;
    font-size: 14px;
    background: @primaryColor;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  .verification-btn.disabled {
    background: @btnBackgroundColor;
    color: @selectedOptionColor;
    cursor: not-allowed;
}
  input {
    flex: 1;
  }
}
.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.right-selector {
  margin-right: 4px;
}

.flex-row {
  flex-direction: row;
}
.flex-1 {
  flex: 1;
}
// .justify- {
//   justify-content: ;
// }



.title {
  margin-bottom: 12px;
  color: #192252;
}
.subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 21px;
}
.title-caption {
  font-size: 14px;
}
.caption {
  font-size: 12px;
}
.button-sm {
  font-size: 12px;
}
.button-md {
  font-size: 14px;
}
.button-lg {
  font-size: 16px;
}
.page {
  padding: 24px;
}

.focus-style {
  &:focus,&:hover {
    outline: none !important;
    box-shadow: 0px 0px 0px 4px rgba(103,170,50,20%) !important;
    border-color: @primaryColor !important;
  }
  &:active {
    box-shadow: unset !important;
  }
}

.btn {
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  padding: 16px;
  color: @primaryColor;
  border: 1px solid @primaryColor;
  &:hover,&:focus {
    border-width: 2px;
  }
  &:active {
    background: @primaryColor;
    color: white;
  }
  // &.primary {
  //   background: @primaryColor;
  //   color: white;
  //   &:hover,&:focus {
  //     border-bottom-width: 4px;
  //     border-bottom-color: #006300;
  //   }
  //   &:active {
  //     background: @primaryDarkColor;
  //     color: white;
  //     box-shadow: 0px 0px 0px 4px rgba(103,170,50,20%) !important;
  //   }
  // }
}
.form-device-wrap{
  margin-bottom: 12px;
}

.form-item {
  margin-bottom: 12px;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
  .form-item-wrap{
    margin-bottom: 12px;
    &:nth-last-child(2) {
      margin-bottom: 0;
      // background-color: red;
    }
  }
  .form-space{
    margin-bottom: 4px;
  }
  .form-title-section{
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .form-hint{
      font-size: 14px;
      color: @primaryColor;
      text-decoration: underline;
      font-weight: 400;
    }
    .form-hint-error{
      font-size: 14px;
      color: @requiredColor;
      text-decoration: underline;
      font-weight: 400;
    }
    .form-title {
      margin-bottom: 0px;
    }
  }
  .form-title {
    margin-bottom: 4px;
    position: relative;
    font-size: 14px;
    color: @titleColor;
    font-weight: bold;  
    .required-star {
      color: @requiredColor;
    }
  }
  .form-content {
    // margin-bottom: 4px;
    .select-btn {
      background-color: @primaryColor;
      color: white;
      border-radius: 8px;
      padding: 10px 20px;
      // width: 100%;
      font-size: 14px;
      // font-weight: bold;
    }
    .select-btn:disabled {
      background-color: #ccc;
      color: #fff;
      cursor: not-allowed;
    }
    input {
      border: 1px solid @borderColor;
      border-radius: 10px;
      padding: 12px;
      font-size: 14px;
      line-height: 1;
      
      // &:hover,&:focus {
      //   outline: none !important;
      //   box-shadow: 0px 0px 0px 4px rgba(103,170,50,20%);
      //   border-color: @primaryColor;
      // }
      &.error {
        // border-color: #D3203F;
      }
    }
    .date-picker {
      position: relative;
      display: flex;
      flex: 1;
      
      // border: 1px solid @borderColor;
      // border-radius: 10px;

      img {
        position: absolute;
        width: 20px;
        height: 20px;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        
      }
    }
    input[type="date"] {
      border: 0px;
      padding: 0px;
      font-size: 14px;
      height: 42px;
      width: 100%;
      background: #fff;
    }
    input[type="radio"],input[type="checkbox"] {
      background-color: #192252;
      // position: absolute;
      // left: -10000px;
      
      // &:checked+label {
      //   border-color: @primaryColor;
      //   background: @primaryLightColor;
      //   &::before {
      //     content: '';
      //     position: absolute;
      //     border: 8px solid @primaryColor;
      //     width: 8px;
      //     height: 8px;
      //     border-radius: 50%;
      //     left: 18px;
      //   }
      //   &::after {
      //     border-color: @primaryColor;
      //   }
      //   &.label-group:nth-last-child(1) {
      //     border-top-left-radius: 0px;
      //     border-bottom-left-radius: 0px;
      //     border-top-right-radius: 10px;
      //     border-bottom-right-radius: 10px;
      //     border-left: 0;
      //     border-right: 2px solid @borderColor;
      //     &::after {
      //       border-color: @primaryColor;
      //       border-width: 2px;
      //       left: -2px;
      //     }
      //   }
      // }
    }
    .choose-item {
      margin-bottom: 24px;
    }
    label {
      position: relative;
      border: 2px solid @borderColor;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      padding: 13px;
      padding-left: 50px;
      display: block;
      background: #fff;
      &.label-group {
        border-right: 0;
      }
      &:active {
        background: @primaryLightColor;
      }
      // &:hover,&:focus {
      //   box-shadow: 0px 0px 0px 4px rgba(103,170,50,20%);
      // }
      &::before {
        content: '';
        position: absolute;
        border: 2px solid @borderColor;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        left: 18px;
      }
      &.label-group::after {
        content: '';
        position: absolute;
        border-right: 2px solid @borderColor;
        right: 0;
        height: 100%;
        top: 0;
      }
      &.label-group:nth-last-child(1) {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 0;
        border-right: 2px solid @borderColor;
        &.label-group::after {
          content: '';
          position: absolute;
          border-right: 0px solid @borderColor;
          left: 0;
          right: unset;
          height: 100%;
          top: 0;
        }
      }
    }
    select {
      width: 100%;
      border-radius: 10px;
      padding: 12px;
      background-color:transparent;
      border-color:transparent;
      -webkit-appearance: none;
      outline: none !important;
      border: 1px solid @borderColor;
      position: relative;
      font-size: 14px;
      background: #fff;
    }
    textarea {
      border: 1px solid @borderColor;
      font-size: 14px;
      padding: 12px;
      width: 100%;
      border-radius: 10px;
    }
  }
  .form-btn {
    margin-top: 4px;
    font-size: 14px;
    color: @primaryColor;
    cursor: pointer;
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
}
.error {
  border-color: @errorColor !important;
}


// .submit-block {
//   margin-left: -24px;
//   margin-right: -24px;
//   margin-bottom: -24px;
//   padding: 24px;
//   box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1);
//   z-index: 10;
//   background: white;
//   position: fixed;
//   bottom: 24px;
//   width: 100%;
//   .btn {
//     margin-bottom: 24px;
//     &:nth-last-child(1) {
//       margin-bottom: 0;
//     }
//   }
// }

.submit-section {
  width: 100%;// 後來新增
  .submit-btn {
    padding: 12px;
    background-color: #ccc;
    // border: 1px solid @primaryColor;
    border-radius: 8px;
    color: @white;
    font-size: 14px;
    font-weight: bold;

    margin-bottom: 20px;
  cursor: not-allowed;
  &.active {
    background-color: @primaryColor;
    cursor: pointer;
  }

  }
  .cancel-btn {
    padding: 12px;
    background-color: #FFF;
    border: 1px solid @primaryColor;
    border-radius: 8px;
    color: @primaryColor;
    font-size: 14px;
    font-weight: bold;
  }
  
}
.form-section{
  margin-bottom: 60px;
}
.submit-section{
  text-align: center;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  width: 80%;
  padding: 32px 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  .modal-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .modal-no-address {
    font-size: 14px;
    color: #666;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .modal-no-address-title {
      font-weight: bold;
      color: @primaryDarkColor;

    }
  }
  .form-content {
    .radio-item {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      input[type="radio"] {
        width: 16px;
        height: 16px;
        margin: 0;
        margin-right: 8px;
        accent-color: @primaryColor;
      }
      label {
        font-size: 14px;
        text-align: left;
      }
    }
  }
}
.modal-actions-underStand {
  display: flex;
  justify-content: center;
  button {
    flex: 1;
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: @primaryColor;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }
}

.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  background-color: @primaryColor;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.modal-actions button:hover {
  background-color: darken(@primaryColor, 10%);
}

.svg-btn {
  width: 24px;
  height: 24px;
  border: none;
  padding: 0;
  background: none;
  img {
    width: 100%;
    height: 100%;
  }
}

.device-item {
display: flex;
align-items: flex-start;
justify-content: space-between;
padding: 12px;
border: 1px solid #ccc;
border-radius: 6px;
margin-bottom: 12px;
.device-item-content {
  flex: 1;
}
.device-item-title {
  margin-bottom: 2px;
  h5 {
    font-size: 14px;
    font-weight: "bold";
    color: #333;
  }
}
.device-item-status {
  padding: 8px 10px;
  background-color: @requiredColor;
  border-radius: 6px;
  margin-right: 12px;
  span {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    margin: 0;
  }
  &.closed {
  background-color: @primaryColor;
}
}
.device-item-info {
  p {
    font-size: 12px;
    font-weight: 400;
    color: #666;
    margin: 0;
    margin-bottom: 2px;
  }
}
.device-item-extra-info {
  // margin-top: 10px;
  p {
    font-size: 12px;
    font-weight: 400;
    color: #333;
    margin: 0;
    margin-bottom: 2px;
  }
  .remark {
    color: #999;
    margin-bottom: 0px;
  }
}
.device-item-btn {
  button {
    padding: 0px;
    border: 0px;
    border-radius: 5px;
    background-color: #fff;
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

}
</style>

<!-- 選單 - 設備清單 -->
<template>
    <div class="home">
      <!-- Loading 畫面 -->
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>
      <section class="form-section">
        <h4 class="title">設備清單</h4>
        <div class="device-item" v-for="device in devices" :key="device.deviceSn">
          <div class="device-item-content">
            <div class="device-item-title">
              <h5>{{ device.deviceSn }}</h5>
              <h5>{{ device.productName }}</h5>
            </div>
            <div class="device-item-info">
              <p>產品型號：{{ device.productModel }}</p>
              <p>購買日期：{{ device.purchasingDate }}</p>
              <p>保固日期：{{ device.warrantyEndDate }}</p>
              <p>購買通路：{{ device.channel }}</p>
              <p>註冊日期：{{ device.registerDate }}</p>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
import upIcon from '@/assets/images/up.svg';
import downIcon from '@/assets/images/down.svg';

import liff from '@line/liff';
export default {
  name: 'DeviceList',
  data() {
    return {
      upIcon,
      downIcon,
      devices: [],
      lineUserId: '',
      isLoading: true,
      currentPage: 1,
      hasMoreData: true
    };
  },
  mounted() {
    this.getProfile().then(() => {
      this.checkRegistration().then(() => {
        this.fetchDevices().finally(() => {
          this.isLoading = false;
        });
      });
    });
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async getProfile() {
      try {
        const profile = await liff.getProfile()
        this.lineUserId = profile.userId
      } catch (err) {
        console.error('Error getting profile:', err)
      }
    },
    async checkRegistration() {
      try {
        const response = await this.$axios.get(`/api/check/line?lineUserId=${this.lineUserId}`);
        if (response.data.code !== 0) {
          alert("用戶尚未註冊")
          this.$router.push('/memberRegister')
          console.error('User not registered:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    },
    async fetchDevices() {
      if (!this.hasMoreData) return;

      this.hasMoreData = false
      try {
        const response = await this.$axios.get(`/api/device`, {
          params: {
            lineUserId: this.lineUserId,
            rowPerPage: 10,
            page: this.currentPage
          }
        });
        if (response.data.code === 0) {
          const newDevices = response.data.data.map(device => ({
            ...device,
            registerDate: device.registerDate.split(' ')[0]
          }));

          if (newDevices.length === 0) {
            this.hasMoreData = false;
          } else {
            this.hasMoreData = true;
            this.devices = [...this.devices, ...newDevices];
            this.currentPage += 1;
          }

          const channelResponse = await this.$axios.post('/api/common', {
            "cnames": ["channel"]
          });
          if (channelResponse.data.code === 0) {
            const channelMap = {};
            channelResponse.data.data.forEach(item => {
              item.dp.forEach(dp => {
                channelMap[dp.value] = dp.title;
              });
            });

            this.devices.forEach(device => {
              device.channel = channelMap[device.channel] || device.channel;
            });
          }
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    },
    handleScroll() {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 10;
      if (bottomOfWindow && this.hasMoreData) {
        this.fetchDevices();
      }
    }
  }
}
</script>

<style lang="less">
@import url('~@/less/params.less');

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>

<!-- 功能 - 會員管理-會員資料 -->
<template>
    <div class="home">
        <div class="success-container">

            <div class="hint-success">
              <button class="svg-btn success-icon-svg">
                <img src="@/assets/images/success.svg" alt="">
              </button>
                <span>服務申請成功</span>
            </div>
            <section class="submit-section">
          <div class="submit-btn active" @click="goToChat">
            回到聊天室
          </div>
        </section>
        </div>
    </div>
</template>


<script>
import liff from '@line/liff';
export default {
  name: 'ServiceRequest',
  methods: {
    goToChat() {
      liff.closeWindow();
    }
  }
}
</script>


<style lang="less">
.success-container {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 20px;
    // height: 100vh;
    justify-content: center;
    align-items: center;
    .submit-btn {
        width: 100%;
    }

}
.success-icon-svg {
    width: 60px;
    height: 60px;
}
.hint-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
}

</style>
import { createRouter, createWebHashHistory } from 'vue-router'

import LoginSerialNumber from '../views/LoginSerialNumber/index.vue'
import DeviceList from '../views/DeviceList/index.vue'
import ServiceRecord from '../views/ServiceRecord/index.vue'
import ServiceRequest from '../views/ServiceRequest/index.vue'
import MemberRegister from '../views/MemberManagement/index.vue'
import OnlineConsultation from '../views/OnlineConsultation/index.vue'
import memberData from '../views/MemberManagement/memberData.vue'
import editPhone from '../views/MemberManagement/editPhone.vue'
import storeSuccess from '../views/MemberManagement/storeSuccess.vue'
import loginSerialNumberComplete from '../views/LoginSerialNumber/Complete.vue'
import ServiceRequestSuccess from '../views/ServiceRequest/Complete.vue'
import Home from '../views/Home/index.vue'
const routes = [
  // 序號登入
  {
    path: '/loginSerialNumber',
    name: 'LoginSerialNumber',
    component: LoginSerialNumber
  },
  // 序號登入 - 完成
  {
    path: '/loginSerialNumber/complete',
    name: 'Complete',
    component: loginSerialNumberComplete
  },
  // 設備清單
  {
    path: '/deviceList',
    name: 'DeviceList',
    component: DeviceList
  },
  // 服務紀錄
  {
    path: '/serviceRecord',
    name: 'ServiceRecord',
    component: ServiceRecord
  },
  // 服務申請
  {
    path: '/serviceRequest',
    name: 'ServiceRequest',
    component: ServiceRequest
  },
  // 服務申請 - 成功
  {
    path: '/serviceRequest/success',
    name: 'ServiceRequestSuccess',
    component: ServiceRequestSuccess
  },
  // 會員註冊
  {
    path: '/memberRegister',
    name: 'MemberRegister',
    component: MemberRegister
  },
  // 會員資料
  {
    path: '/memberData',
    name: 'memberData',
    component: memberData
  },
  // 會員資料
  {
    path: '/',
    name: 'home',
    component: Home
  },
  // 會員資料 - 成功
  {
    path: '/memberData/storeSuccess',
    name: 'storeSuccess',
    component: storeSuccess
  },
  // 會員資料 - 修改手機
  {
    path: '/editPhone',
    name: 'editPhone',
    component: editPhone
  },
  // 線上諮詢
  {
    path: '/onlineConsultation',
    name: 'OnlineConsultation',
    component: OnlineConsultation
  }
]

// 創建 router 實例
const router = createRouter({
  history: createWebHashHistory(), // 修改為 createWebHashHistory
  routes
})

export default router

<!-- 功能 - 會員管理-會員資料 -->
<template>
    <div class="home">
      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>
        <section class="form-section">
            <h4 class="title">會員資料</h4>

            <!-- 姓名 -->
            <div class="form-item">
                <div class="form-title required"><span class="required-star">*</span>姓名</div>
                <div class="form-content d-flex">
                    <input type="text" class="flex-1 focus-style" placeholder="請輸入姓名" v-model="name">
                </div>
            </div>

            <!-- 手機 -->
            <div class="form-item">
                <div class="form-title-section">
                    <div class="form-title required"><span class="required-star">*</span>手機</div>
                    <a class="form-hint" @click="goToEditPhone">修改手機</a>
                </div>
                <div class="form-content d-flex">
                    <input type="text" class="flex-1 focus-style" placeholder="請輸入電話" v-model="phone" :disabled="true">
                </div>
            </div>

            <!-- Email -->
            <div class="form-item">
                <div class="form-title required">Email</div>
                <div class="form-content d-flex">
                    <input type="text" class="flex-1 focus-style" placeholder="請輸入Email" v-model="email">
                </div>
            </div>

            <!-- 地址 -->
            <div class="form-item">
                <div v-for="(address, index) in addresses" :key="index" class="form-item-wrap">
                    <div class="form-title-section">
                        <div class="form-title required">地址{{ index + 1 }}</div>
                        <a v-if="addresses.length > 1" class="form-hint-error" @click="sentDeleteLocation(index)">刪除地址</a>
                    </div>
                    <div class="form-content d-flex form-space">
                      <div class="select-wrap right-selector">
                        <select class="focus-style" v-model="address.city" @change="onCityChange(index)">
                          <option value="">請選擇城市</option>
                          <option v-for="city in cities" :key="city.value" :value="city.value">{{ city.title }}</option>
                        </select>
                      </div>
                      <div class="select-wrap">
                        <select class="focus-style" v-model="address.area" @change="onDistrictChange(index)">
                            <option value="">請選擇地區</option>
                            <option v-for="district in address.districts" :key="district.value" :value="district.value">{{ district.title }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="form-content d-flex">
                        <input v-model="address.address" type="text" class="flex-1 focus-style" placeholder="請輸入地址">
                    </div>
                </div>
                <div class="form-btn" @click="addAddress">
                    + 新增地址
                </div>
            </div>
        </section>
        <section class="submit-section">
            <div class="submit-btn" :class="{ 'active': allFieldsFilled }" @click="saveMemberData" :disabled="!allFieldsFilled">儲存</div>
            <div class="cancel-btn" @click="cancelForm">取消</div>
        </section>

        <!-- 成功 - 刪除地址 -->
        <div v-if="delLocationModal" class="modal-overlay">
            <div class="modal-content">
                <div class="hint-success">
                    <span>刪除地址?</span>
                </div>
                <div class="modal-actions">
                    <button class="close-btn" @click="cancelDelete">取消</button>
                    <button class="delete-btn" @click="acceptDelete">刪除</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import liff from '@line/liff';
export default {
  name: 'OnlineConsultation',
  data() {
    return {
      delLocationModal: false,
      addresses: [{}],
      addressToDelete: null,
      cities: [],
      districts: [],
      selectedCity: '',
      selectedDistrict: '',
      name: '',
      phone: '',
      email: '',
      lineUserId: '',
      isLoading: true,
    }
  },
  mounted() {
    this.getProfile().then(() => {
      this.checkRegistration().then(() => {
          this.loadCommonData().then(() => {
          this.getMemberData().finally(() => {
            this.isLoading = false;
          });
        });
      });
    });
  },
  computed: {
    allFieldsFilled() {
      
      const phoneRegex = /^09\d{8}$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const addressesFilled = this.addresses.every(address => address.city && address.area && address.address);
      const emailValid = !this.email || emailRegex.test(this.email); // email 不是必填，但如果有值的話要符合格式
      return this.name && this.phone && phoneRegex.test(this.phone) && emailValid && addressesFilled;
    }
  },
  methods: {
    addAddress() {
      this.addresses.push({});
    },
    goToEditPhone() {
      this.$router.push('/editPhone');
    },
    async getProfile() {
      try {
        const profile = await liff.getProfile();
        console.log(profile);
        this.profile = profile;
        this.lineUserId = profile.userId;
      } catch (err) {
        console.error('Error getting profile:', err);
      }
    },
    sentDeleteLocation(index) {
      this.addressToDelete = index;
      this.delLocationModal = true;
    },
    cancelDelete() {
      this.delLocationModal = false;
      this.addressToDelete = null;
    },
    acceptDelete() {
      if (this.addressToDelete !== null) {
        this.addresses.splice(this.addressToDelete, 1);
        this.addressToDelete = null;
      }
      this.delLocationModal = false;
    },
    loadCommonData() {
      return this.$axios.post('/api/common', {
        cnames: ["city"]
      })
      .then(response => {
        for (let i of response.data.data) {
          if (i.cname === "city") {
            this.cities = i.dp; 
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
    },
    onCityChange(index) {
      const selectedCity = this.cities.find(city => city.value === this.addresses[index].city);
      this.addresses[index].districts = selectedCity ? selectedCity.districts : [];
      this.addresses[index].area = '';

    },
    onDistrictChange(index) {
      console.log(this.addresses[index]);
    },
    saveMemberData() {
        if(!this.allFieldsFilled) {
          return;
        }
        const payload = {
            lineUserId: this.lineUserId,
            name: this.name,
            phone: this.phone,
            email: this.email,
            addresses: this.addresses.map((address, index) => ({
                memberAddressId: address.memberAddressId ? address.memberAddressId : "",
                city: address.city,
                area: address.area,
                address: address.address,
                isDefault: index === 0 ? 1 : 0
            }))
        };
        console.log(payload);

        this.$axios.put('/api/member/update', payload)
            .then(response => {
                if (response.data.code === 0) {
                    this.$router.push('/memberData/storeSuccess');
                } else {
                    console.error('更新失敗', response.data.message);
                }
              })
              .catch(error => {
                console.error('更新失敗', error);
            });
      },
      cancelForm() {
        this.name = '';
        this.phone = '';
        this.email = '';
        console.log('表單已重置');
        // 關閉 liff
        liff.closeWindow();
      },
      async getMemberData() {
          try {
              const response = await this.$axios.get(`/api/member/${this.lineUserId}`);
              const memberData = response.data.data;
              this.name = memberData.name;
              this.phone = memberData.mobile;
              this.email = memberData.email || '';
              this.addresses = memberData.addresses.length > 0 ? memberData.addresses.map(address => ({
                city: address.city,
                area: address.area,
                address: address.address,
                memberAddressId: address.memberAddressId,
                districts: this.cities.find(city => city.value === address.city).districts
              })) : [{}];
          } catch (err) {
              console.error('獲取用戶資料失敗:', err);
          }
      },
      async checkRegistration() {
      try {
        const response = await this.$axios.get(`/api/check/line?lineUserId=${this.lineUserId}`);
        if (response.data.code !== 0) {
          alert("用戶尚未註冊")
          this.$router.push('/memberRegister')
          console.error('User not registered:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    },
  }
}
</script>

<style lang="less">
@import url('~@/less/params.less');

.modal-actions {
  display: flex;
  justify-content: space-between;
  button {
    flex: 1;
  }
  .close-btn {
    margin-right: 12px;
    background-color: #FFF;
    border: #666666 solid 1px;
    color: #666666;
  }
  .delete-btn {
    background-color: @requiredColor;
    color: #FFF;
  }
}
</style>
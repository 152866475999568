<!-- 功能 - 修改手機 -->
<template>
  <div class="home">
    <section class="form-section">
      <h4 class="title">修改手機</h4>

      <!-- 手機 -->
      <div class="form-item">
        <div class="form-title required">
          <span class="required-star">*</span>手機
        </div>
        <div class="form-content d-flex">
          <div class="input-wrap flex-1">
            <input type="text" class="focus-style flex-1" placeholder="請輸入手機" v-model="phone" @input="validatePhone"
              :disabled="isSending || countdown > 0">
            <div class="verification-btn" :class="{ 'disabled': !isPhoneValid || countdown > 0 }"
              @click="sendVerificationCode" :disabled="!isPhoneValid || countdown > 0">
              {{ countdown > 0 ? '重新發送' : '發送驗證碼' }}
            </div>
          </div>
        </div>
        <div v-if="countdown > 0" class="verification-sec">
          <span class="time-text">{{ countdownTime }}</span>
          <span class="hint-text">&nbsp;後重新發送</span>
        </div>
      </div>

      <!-- 驗證碼 -->
      <div class="form-item">
        <div class="form-title required"><span class="required-star">*</span>驗證碼</div>
        <div class="form-content d-flex">
          <input type="text" class="flex-1 focus-style" placeholder="請輸入驗證碼" v-model="verificationCode">
        </div>
      </div>


    </section>
    <section class="submit-section">
      <div class="submit-btn" :class="{ 'active': allFieldsFilled }" @click="submitForm" :disabled="!allFieldsFilled">
        送出
      </div>
      <div class="cancel-btn" @click="cancelForm">取消</div>
    </section>

    <!-- 成功 - 彈跳視窗 -->
    <div v-if="showSuccessModal" class="modal-overlay">
      <div class="modal-content">
        <div class="hint-success">
          <button class="svg-btn success-icon-svg">
            <img src="@/assets/images/success.svg" alt="">
          </button>
          <span>驗證成功</span>
        </div>
        <div class="modal-actions">
          <button class="chat-btn" @click="goToChat">回聊天室</button>
          <button @click="editMember" class="edit-member-btn">
            <span class="whitespace-nowrap">編輯</span>
            <span class="whitespace-nowrap">會員資料</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 失敗 - 彈跳視窗 -->
    <div v-if="showErrorModal" class="modal-overlay">
      <div class="modal-content">
        <div class="hint-success">
          <span>驗證失敗，請重新輸入</span>
        </div>
        <div class="modal-actions-underStand">
          <button @click="underStand">了解</button>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import liff from '@line/liff';

export default {
  name: 'EditPhone',
  data() {
    return {
      phone: '',
      verificationCode: '',
      lineUserId: '',
      isPhoneValid: false,
      isSending: false,
      hasSent: false,
      countdown: 0,
      countdownTime: '1:30',
      showSuccessModal: true,
      showErrorModal: false,
    }
  },
  computed: {
    allFieldsFilled() {
      // 確認所有必填欄位是否都有值
      return this.phone && this.verificationCode && this.hasSent;
    }
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    async getProfile() {
      try {
        const profile = await liff.getProfile()
        console.log(profile)
        this.lineUserId = profile.userId
      } catch (err) {
        console.error('Error getting profile:', err)
      }
    },
    validatePhone() {
      const phoneRegex = /^09\d{8}$/;
      this.isPhoneValid = phoneRegex.test(this.phone);
    },
    sendVerificationCode() {
      if(this.isSending) {
        return;
      }
      if (this.isPhoneValid) {
        this.isSending = true;
        this.hasSent = true;
        this.startCountdown(90); // 1:30 秒倒數
        // 發送驗證碼的邏輯
        this.$axios.post('/api/otp/send', {
          mobile: this.phone
        }).then(response => {
          console.log('驗證碼已發送', response);
        }).catch(error => {
          console.error('發送驗證碼失敗', error);
        });
      }
    },
    startCountdown(seconds) {
      this.countdown = seconds;
      this.updateCountdownTime();
      const interval = setInterval(() => {
        this.countdown--;
        this.updateCountdownTime();
        if (this.countdown <= 0) {
          clearInterval(interval);
          this.isSending = false;
        }
      }, 1000);
    },
    updateCountdownTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      this.countdownTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    submitForm() {
      if (this.allFieldsFilled) {
        console.log('提交表單');
        console.log('手機:', this.phone);
        console.log('驗證碼:', this.verificationCode);

        // 更改手機號碼
        this.$axios.put('/api/member/mobile', {
          lineUserId: this.lineUserId,
          mobile: this.phone,
          otpCode: this.verificationCode
        }).then(response => {
          if (response.data.code === 0) {
            console.log('驗證成功', response);
            this.showSuccessModal = true; // 成功 - 顯示彈跳視窗
          } else {
            console.error('驗證失敗', response.data.message);
            this.showErrorModal = true; // 失敗 - 顯示彈跳視窗
          }
        }).catch(error => {
          console.error('驗證失敗', error);
          this.showErrorModal = true; // 失敗 - 顯示彈跳視窗
        });
      }
    },

    cancelForm() {
      this.phone = '';
      this.verificationCode = '';
      this.$router.push('/memberData');
      
    },
    goToChat() {
      // 回到聊天室
      liff.closeWindow();

    },
    editMember() {
      this.$router.push('/memberData');
    },
    underStand() {
      this.showErrorModal = false;
    }
  }
}
</script>

<style scoped>
.edit-member-btn {
  
  span {
    white-space: nowrap;
  }
}
</style>
<!-- 選單 - 會員管理 -->
<template>
  <div class="home">
    <section class="form-section">
      <h4 class="title">會員註冊</h4>
      <!-- 姓名 -->
      <div class="form-item">
        <div class="form-title required"><span class="required-star">*</span>姓名</div>
        <div class="form-content d-flex">
          <input type="text" class="flex-1 focus-style" placeholder="請輸入姓名" v-model="name">
        </div>
      </div>

      <!-- 手機 -->
      <div class="form-item">
        <div class="form-title required">
          <span class="required-star">*</span>手機
        </div>
        <div class="form-content d-flex">
          <div class="input-wrap flex-1">
            <input type="text" class="focus-style flex-1" placeholder="請輸入手機" v-model="phone" @input="validatePhone"
              :disabled="isSending || countdown > 0">
            <div class="verification-btn" :class="{ 'disabled': !isPhoneValid || countdown > 0 }"
              @click="sendVerificationCode" :disabled="!isPhoneValid || countdown > 0">
              {{ countdown > 0 ? '重新發送' : '發送驗證碼' }}
            </div>
          </div>
        </div>
        <div v-if="countdown > 0" class="verification-sec">
          <span class="time-text">{{ countdownTime }}</span>
          <span class="hint-text">&nbsp;後重新發送</span>
        </div>
      </div>

      <!-- 驗證碼 -->
      <div class="form-item">
        <div class="form-title required"><span class="required-star">*</span>驗證碼</div>
        <div class="form-content d-flex">
          <input type="text" class="flex-1 focus-style" placeholder="請輸入驗證碼" v-model="verificationCode">
        </div>
      </div>

    </section>
    <section class="submit-section">
      <div class="submit-btn" :class="{ 'active': allFieldsFilled }" @click="submitForm" :disabled="!allFieldsFilled">
        送出
      </div>
      <div class="cancel-btn" @click="cancelForm">取消</div>
    </section>
    <!-- 成功 - 彈跳視窗 -->
    <div v-if="showSuccessModal" class="modal-overlay">
      <div class="modal-content">
        <div class="hint-success">
          <button class="svg-btn success-icon-svg">
            <img src="@/assets/images/success.svg" alt="">
          </button>
          <span>註冊成功</span>
        </div>
        <div class="modal-actions">
          <button class="chat-btn" @click="goToChat">回聊天室</button>
          <button @click="editMember" class="edit-member-btn">
            <span class="whitespace-nowrap">編輯</span>
            <span class="whitespace-nowrap">會員資料</span>
          </button>
        </div>
      </div>
    </div>

    <!-- 失敗 - 彈跳視窗 -->
    <div v-if="showErrorModal" class="modal-overlay">
      <div class="modal-content">
        <div class="hint-success">
          <span>驗證失敗，請重新輸入</span>
        </div>
        <div class="modal-actions-underStand">
          <button @click="underStand">了解</button>
        </div>
      </div>
    </div>

    <div class="warring-section" v-if="isWarring">
        <img class="warring-icon" src="@/assets/images/warring.svg" alt="">
        <span class="warring-text">{{ warringText }}</span>
      </div>

      <div class="sending-section" v-if="isSendingAlert">
        <img class="sending-icon" src="@/assets/images/sending.svg" alt="">
        <span class="sending-text">{{ isSendingText }}</span>
      </div>
  </div>
</template>

<script>
// 
import liff from '@line/liff';

export default {
  name: 'OnlineConsultation',
  data() {
    return {
      name: '',
      phone: '',
      verificationCode: '',
      isPhoneValid: false,
      isSending: false,
      countdown: 0,
      countdownTime: '1:30',
      showSuccessModal: false,
      showErrorModal: false,
      hasSent: false,
      lineUserId: '',
      isWarring: false,
      warringText: '驗證碼發送失敗',
      isSendingAlert: false,
      isSendingText: '驗證碼已送出'
    }
  },
  mounted() {
    this.getProfile().then(() => {
      this.checkRegistration().then(() => {
      });
    });
  },
  computed: {
    allFieldsFilled() {
      // 確認所有必填欄位是否都有值
      return this.name && this.phone && this.verificationCode && this.hasSent;
    }
  },
  methods: {
    async getProfile() {
      try {
        const profile = await liff.getProfile()
        this.lineUserId = profile.userId
        console.log(this.lineUserId)
      } catch (err) {
      console.error('Error getting profile:', err)
      }
    },
    validatePhone() {
      const phoneRegex = /^09\d{8}$/;
      this.isPhoneValid = phoneRegex.test(this.phone);
    },
    sendVerificationCode() {
      
      if(this.isSending) {
        return;
      }
      if (this.isPhoneValid) {
        this.isSending = true;
        this.hasSent = true;
        this.startCountdown(90); // 1:30 秒倒數
        // 發送驗證碼的邏輯
        this.$axios.post('/api/otp/send', {
          mobile: this.phone
        }).then(response => {
          if (response.data.code !== 0) {
            console.log('發送驗證碼失敗', response);
            this.isWarring = true;
            setTimeout(() => {
              this.isWarring = false;
            }, 2000);
          } else {
            console.log('驗證碼已發送', response);
            this.isSendingAlert = true;
            setTimeout(() => {
              this.isSendingAlert = false;
            }, 2000);
          }
        }).catch(error => {
          console.error('發送驗證碼失敗', error);
          this.isWarring = true;
          setTimeout(() => {
            this.isWarring = false;
          }, 2000);
        });

        // this.isSendingAlert = true;
        // this.isWarring = true;

      }
    },
    startCountdown(seconds) {
      this.countdown = seconds;
      this.updateCountdownTime();
      const interval = setInterval(() => {
        this.countdown--;
        this.updateCountdownTime();
        if (this.countdown <= 0) {
          clearInterval(interval);
          this.isSending = false;
        }
      }, 1000);
    },
    updateCountdownTime() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      this.countdownTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    submitForm() {
      if (this.allFieldsFilled) {
        console.log('提交表單');
        console.log('姓名:', this.name);
        console.log('lineUserId:', this.lineUserId);
        console.log('手機:', this.phone);
        console.log('驗證碼:', this.verificationCode);

        this.$axios.post('/api/register/line', {
          lineUserId: this.lineUserId,
          name: this.name,
          mobile: this.phone,
          otpCode: this.verificationCode
        }).then(response => {
          if (response.data.code === 0) {
            console.log('註冊成功', response);
            this.showSuccessModal = true;
          } else {
            console.error('註冊失敗', response.data.data.message);
            this.showErrorModal = true;
            
          }
        }).catch(error => {
          console.error('註冊失敗', error);
          this.showErrorModal = true;
        });
      }
    },

    cancelForm() {
      // 取消操作邏輯
      this.name = '';
      this.phone = '';
      this.verificationCode = '';
      console.log('表單已重置');
      // 關閉 liff
      liff.closeWindow();
    },
    goToChat() {
      // 回到聊天室
      liff.closeWindow();
    },
    editMember() {
      
      this.$router.push('/memberData');
    },
    underStand() {
      this.showErrorModal = false;
    },
    async checkRegistration() {
      try {
        const response = await this.$axios.get(`/api/check/line?lineUserId=${this.lineUserId}`);
        if (response.data.code === 0) {
          this.$router.push('/memberData')
          console.error('User not registered:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    },
  }
}
</script>

<style lang="less">
@import url('~@/less/params.less');

.edit-member-btn {
  
  span {
    white-space: nowrap;
  }
}

.modal-actions {
  display: flex;
  .chat-btn {
    flex: 1;
    margin-right: 12px;
    background-color:   #FFF;
    border: #666666 solid 1px;
    color: #666666;
  }
}

.hint-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 24px;
}

.success-icon-svg{
  width: 50px;
  height: 50px;
  margin-bottom: 12px;
}
.verification-sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 400;

  .time-text {
    color: @primaryColor;
  }
}

input[disabled] {
  background-color: #E8E8E8;
  cursor: not-allowed;
}

.warring-section {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F9E5E5;
  padding: 8px 15px;
  border-radius: 10px;
  border: 1px solid #F5C1C1;
  width: 100%;
  .warring-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .warring-text {
    font-size: 14px;
    color: #C00000;
  }
}

.sending-section {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E6F5EA;
  padding: 8px 15px;
  border-radius: 10px;
  border: 1px solid #006300;
  width: 100%;
  .sending-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .sending-text {
    font-size: 14px;
    color: #0E7A3F;
  }
}

</style>
<!-- 選單 - 服務紀錄 -->
<template>
  <div class="home">
    <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>
    <section class="form-section">
      <h4 class="title">服務紀錄</h4>
      <div v-for="(device, index) in devices" :key="index" class="device-item">
        <div class="device-item-status" :class="{ closed: device.serviceApplyStatus === 1 || device.serviceApplyStatus === 2 }">
          <span>{{ device.serviceApplyStatus === 1 || device.serviceApplyStatus === 2 ? "處理中" : "已結案" }}</span>
        </div>
        <div class="device-item-content">
          <div class="device-item-title">
            <h5>{{ device.productName }}</h5>
          </div>
          <div class="device-item-info">
            <p>申請日期：{{ device.serviceDate }}</p>
          </div>
          <div class="device-item-extra-info">
            <p>服務類別：{{ device.serviceTypeText }}</p>
            <!-- <p>{{ serviceTypes[device.serviceType] }}</p> -->
            <p>{{ device.serviceDesc }}</p>
            <p>
              地址：{{ device.serviceAddressText }}
            <!-- {{ `${this.cityData?.find(c => c.value === device.serviceCity)?.title || ''}${this.cityData?.find(c => c.value === device.serviceCity)?.districts.find(d => d.value === device.serviceArea)?.title || ''}${device.serviceAddress}` }} -->
            </p>
            <p class="remark">{{ device.serviceNote }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import upIcon from '@/assets/images/up.svg';
import downIcon from '@/assets/images/down.svg';
import liff from '@line/liff';

export default {
  name: 'DeviceList',
  data() {
    return {
      title: '瓦斯熱水器',
      devices: [],
      upIcon,
      downIcon,
      lineUserId: '',
      serviceTypes: {},
      cityData: [],
      isLoading: true,
      currentPage: 1,
      hasMoreData: true
    };
  },
  mounted() {
    this.getProfile().then(async () => {
      await this.checkRegistration();
      await this.fetchServiceTypes();
    });
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async fetchServiceRecords() {
      if (!this.hasMoreData) return;

      this.hasMoreData = false
      axios.get('/api/service', {
        params: {
          lineUserId: this.lineUserId,
          rowPerPage: 10, 
          page: this.currentPage
        }
      })
      .then(response => {
        if (response.data.code === 0) {
          const newDevices = response.data.data.map(device => {
            console.log("device  aaa",device)
            // const city = this.cityData?.find(c => c.value === device.serviceCity)?.title || '';
            // const district = this.cityData?.find(c => c.value === device.serviceCity)?.districts.find(d => d.value === device.serviceArea)?.title || '';
            


            console.log("cityData aaa",this.cityData)
            console.log("cserviceTypesity aaa", this.serviceTypes)
            return {
              ...device,
              serviceDate: device.serviceDate == null ? '沒有日期' : new Date(device.serviceDate).toISOString().split('T')[0],
              // serviceAddressText: `12345`
              serviceTypeText: this.serviceTypes[device.serviceType],
              serviceAddressText: `${this.cityData?.find(c => c.value === device.serviceCity)?.title || ''}${this.cityData?.find(c => c.value === device.serviceCity)?.districts.find(d => d.value === device.serviceArea)?.title || ''}${device.serviceAddress}`,
              // serviceAddressText: `${city} ${district} ${device.serviceAddressText}`
            };
          });

          this.isLoading = false;
          if (newDevices.length === 0) {
            this.hasMoreData = false;
          } else {
            this.hasMoreData = true;
            this.devices = [...this.devices, ...newDevices];
            this.currentPage += 1;
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
    },
    async fetchServiceTypes() {
      axios.post('/api/common', {
        cnames: ['service_type','city']
      })
      .then(response => {
        if (response.data.code === 0) {
          const serviceTypes = response.data.data.find(item => item.cname === 'service_type').dp;
          this.serviceTypes = serviceTypes.reduce((acc, type) => {
            acc[type.value] = type.title;
            return acc;
          }, {});

          this.cityData = response.data.data.find(item => item.cname === 'city').dp;

          console.log("cityData", this.cityData);
          console.log(this.serviceTypes);

          // 在這裡調用 fetchServiceRecords 確保在 fetchServiceTypes 完成後執行
          this.fetchServiceRecords();
        }
      })
      .catch(error => {
        console.error(error);
      });
    },
    async getProfile() {
      try {
        const profile = await liff.getProfile();
        console.log(profile);
        this.lineUserId = profile.userId;
      } catch (err) {
        console.error('Error getting profile:', err);
      }
    },
    async checkRegistration() {
      try {
        const response = await this.$axios.get(`/api/check/line?lineUserId=${this.lineUserId}`);
        if (response.data.code !== 0) {
          alert("用戶尚未註冊")
          this.$router.push('/memberRegister')
          console.error('User not registered:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    },
    handleScroll() {
      const bottomOfWindow = window.innerHeight + window.scrollY >= document.documentElement.offsetHeight - 10;
      if (bottomOfWindow && this.hasMoreData) {
        this.fetchServiceRecords();
      }
    }
  }
  
}
</script>

<style lang="less">
@import url('~@/less/params.less');


</style>

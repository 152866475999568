<!-- 功能 - 服務申請 -->
<template>
  <div class="home">
    <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>
    <section class="form-section">
      <h4 class="title">服務申請</h4>
      <!-- 設備 -->
      <div class="form-item">
        <div class="form-item-wrap" v-for="(device, index) in devices" :key="index">
          <div class="form-title-section">
            <div class="form-title required"><span class="required-star">*</span>設備</div>
          </div>
          <div class="form-content d-flex flex-column">
            <div class="select-wrap">
              <select class="focus-style" v-model="selectedDevice">
                <option value="">請選擇設備</option>
                <option v-for="device in devicesList" :key="device.value" :value="device.value">
                  {{ device.title }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- 服務類別 -->
      <div class="form-item">
        <div class="form-title required"><span class="required-star">*</span>服務類別</div>
        <div class="form-content d-flex flex-column">
          <div class="select-wrap">
            <select class="focus-style" v-model="selectedServiceType">
              <option value="">請選擇服務類別</option>
              <option v-for="serviceType in serviceTypes" :key="serviceType.value" :value="serviceType.value">
                {{ serviceType.title }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- 問題描述 -->
      <div class="form-item">
        <div class="form-title required"><span class="required-star">*</span>問題描述</div>
        <div class="form-content d-flex">
          <textarea name="" id="" rows="3" placeholder="請輸入問題" class="focus-style" v-model="problemDescription"></textarea>
        </div>
      </div>

      <!-- 聯絡人 -->
      <div class="form-item">
        <div class="form-title required"><span class="required-star">*</span>聯絡人</div>
        <div class="form-content d-flex">
          <input type="text" class="flex-1 focus-style" placeholder="請輸入姓名" v-model="contactPerson">
        </div>
      </div>

      <!-- 聯絡電話 -->
      <div class="form-item">
        <div class="form-title required"><span class="required-star">*</span>聯絡電話</div>
        <div class="form-content d-flex">
          <input type="text" class="flex-1 focus-style" placeholder="請輸入電話" v-model="contactMobile">
        </div>
      </div>


      <!-- 地址 -->
      <div class="form-item">
        <div class="form-title-section">
            <div class="form-title required"><span class="required-star">*</span>地址</div>
          <a class="form-hint" @click="openUseMemberLocationModal">使用會員資料地址</a> <!-- 修改這行 -->
        </div>
        <div class="form-content d-flex form-space">
          <div class="select-wrap right-selector">
            <select class="focus-style" v-model="selectedCity" @change="onCityChange">
              <option value="">請選擇城市</option>
              <option v-for="city in cities" :key="city.value" :value="city.value">{{ city.title }}</option>
            </select>
          </div>
          <div class="select-wrap">
            <select class="focus-style" v-model="selectedDistrict">
              <option value="">請選擇地區</option>
              <option v-for="district in districts" :key="district.value" :value="district.value">{{ district.title }}</option>
            </select>
          </div>
        </div>
        <div class="form-content d-flex">
          <input type="text" class="flex-1 focus-style" placeholder="請輸入地址" v-model="address">
        </div>
      </div>

    </section>
    <section class="submit-section">
      <div class="submit-btn" :class="{ 'active': allFieldsFilled }" @click="submitForm" :disabled="!allFieldsFilled" >送出</div>
      <div class="cancel-btn" @click="cancelForm">取消</div>

    </section>

    <!-- 使用會員資料地址 -->
    <div v-if="useMemberLocationModal" class="modal-overlay">
      <div class="modal-content">
        <div class="modal-title">
          <span>選擇會員資料地址</span>
        </div>
        <div class="form-content d-flex flex-column">
          <template v-if="addressArray.length > 0">
            <div class="radio-item" v-for="(address, index) in addressArray" :key="index">

              <input type="radio" :id="'address-' + index" :value="index" v-model="selectedAddress">
              <label :for="'address-' + index">
                {{ `${this.cities?.find(c => c.value === address.city)?.title || ''}${this.cities?.find(c => c.value === address.city)?.districts.find(d => d.value === address.area)?.title || ''}${address.address}` }}
              </label>
            </div>
          </template>
          <template v-else>
            <div class="modal-no-address">
              <span class="modal-no-address-title">尚未新增任何地址</span>
              <span>在「會員管理」中可新增地址，並在下操作時選擇已儲存的地址</span>
            </div>
          </template>
        </div>
        <div v-if="addressArray.length > 0" class="modal-actions">
          <button class="close-btn" @click="cancelUseMemberLocation">取消</button>
          <button class="select-btn" @click="acceptUseMemberLocation" :disabled="selectedAddress===''">使用</button>
        </div>
        <div v-else class="modal-actions-underStand">
          <button  @click="cancelUseMemberLocation">了解</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import liff from '@line/liff';

export default {
  name: 'OnlineConsultation',
  data() {
    return {
      devices: [{}],
      devicesList: [],
      useMemberLocationModal: false,
      addressArray: [],
      selectedAddress: '',
      cities: [],
      districts: [],
      selectedCity: '',
      selectedDistrict: '',
      serviceTypes: [],
      lineUserId: '',
      contactPerson: '',
      contactMobile: '',
      problemDescription: '',
      address: '',
      selectedServiceType: '',
      selectedDevice: '',
      isLoading: true,
    };
  },
  mounted() {
    this.getProfile().then(() => {
      this.checkRegistration().then(() => {
        this.loadCommonData().then(() => {
          this.loadUserData().then(() => {
            this.loadDevices().finally(() => {
          this.isLoading = false;
        });
          });
        });
      });
    });
  },
  computed: {
    allFieldsFilled() {
      const mobilePattern = /^09\d{8}$/;
      return this.contactPerson && mobilePattern.test(this.contactMobile) && this.problemDescription && this.selectedServiceType && this.selectedCity && this.selectedDistrict && this.address && this.selectedDevice;
    }
  },
  methods: {
    async loadUserData() {
      this.$axios.get(`/api/member/${this.lineUserId}`)
      .then(response => {
        if (response.data.code === 0) {
          const memberData = response.data.data;
          console.log(memberData)
          this.contactPerson = memberData.name;
          this.contactMobile = memberData.mobile;
          this.addressArray = memberData.addresses.map(address => ({
            // title: address.city+address.area+address.address,
            city: address.city,
            area: address.area,
            address: address.address
          }));
          console.log('User data loaded successfully');
        } else {
          console.error('Error loading user data:', response.data.message);
        }
      })
      .catch(error => {
        console.error('Error loading user data:', error);
      });
    },
    async getProfile() {
      try {
        const profile = await liff.getProfile()
        console.log(profile)
        this.lineUserId = profile.userId
      } catch (err) {
      console.error('Error getting profile:', err)
      }
    },
    cancelUseMemberLocation() {
      this.useMemberLocationModal = false;
    },
    acceptUseMemberLocation() {
        this.useMemberLocationModal = false;
        this.selectedCity = this.addressArray[this.selectedAddress].city;
        this.onCityChange()
        this.selectedDistrict = this.addressArray[this.selectedAddress].area;
        this.address = this.addressArray[this.selectedAddress].address;
        this.selectedAddress = ''
    },
    openUseMemberLocationModal() {
      this.useMemberLocationModal = true;
    },
    submitForm() {
      if (!this.allFieldsFilled) {
        return;
      }
      const payload = {
        lineUserId: this.lineUserId,
        deviceSnId: this.selectedDevice,
        serviceType: parseInt(this.selectedServiceType),
        memberDesc: this.problemDescription,
        contactPerson: this.contactPerson,
        contactMobile: this.contactMobile,
        city: this.selectedCity,
        area: this.selectedDistrict,
        address: this.address
      };
      console.log(payload);
      this.$axios.post('/api/service', payload)
        .then(response => {
          if (response.data.code === 0) {
            this.$router.push('/serviceRequest/success');
          } else {
            console.error('申請失敗', response.data.message);
          }
        })
        .catch(error => {
          console.error('申請失敗', error);
        });
    },
    async loadCommonData() {
      this.$axios.post('/api/common', {
        cnames: ["service_type", "city"]
      })
      .then(response => {
        
        for (let i of response.data.data) {
          if (i.cname === "service_type") {
            this.serviceTypes = i.dp;
          } else if (i.cname === "city") {
            this.cities = i.dp;
          } 
        }
      })
      .catch(error => {
        console.error(error);
      });
    },
    async loadDevices() {
      this.$axios.get('/api/device', {
          params: {
            lineUserId: this.lineUserId,
            rowPerPage: 10, 
            page: 1
          }
        })
      .then(response => {
        if (response.data.code === 0) {
          this.devicesList = response.data.data.map(device => ({
            title: `${device.deviceSn}-${device.productName}`,
            value: device.deviceSnId
          }));
        }
      })
      .catch(error => {
        console.error('Error loading devices:', error);
      });
    },
    onCityChange() {
      const selectedCity = this.cities.find(city => city.value === this.selectedCity);
      this.districts = selectedCity ? selectedCity.districts : [];
      this.selectedDistrict = '';
    },
    cancelForm() {
      this.name = '';
      this.phone = '';
      this.verificationCode = '';
      console.log('表單已重置');
      // 關閉 liff
      liff.closeWindow();
    },
    async checkRegistration() {
      try {
        const response = await this.$axios.get(`/api/check/line?lineUserId=${this.lineUserId}`);
        if (response.data.code !== 0) {
          alert("用戶尚未註冊")
          this.$router.push('/memberRegister')
          console.error('User not registered:', response.data.message);
        }
      } catch (error) {
        console.error('Error checking registration:', error);
      }
    },
  }
}
</script>

<style scoped>
/* 新增這段樣式 */
/* input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid var(--primaryDarkColor);
  border-radius: 50%;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
}

input[type="radio"]:checked::before {
  content: '';
  width: 10px;
  height: 10px;
  background-color: var(--primaryDarkColor);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
/* .select-btn {
  background-color: var(--primaryDarkColor);
  color: white;
  border-radius: 10px;
  padding: 12px;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}
.select-btn:disabled {
  background-color: #ccc;
  color: #fff;
  cursor: not-allowed;
} */
</style>